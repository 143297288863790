import { Capacitor } from '@capacitor/core';
import { Platform } from '@og-shared/types';

import { sentryLogError } from '../services/sentry';
import { inIframe } from './utils';

export function getPlatform() {
  if (inIframe()) {
    Capacitor.getPlatform = () => 'web';
  }
  const platform = Capacitor.getPlatform() as Platform;
  if (platform !== 'ios' && platform !== 'android' && platform !== 'web') {
    console.error('unknown platform', platform);
    sentryLogError('unknown platform', { extra: { platform } });
  }
  return platform;
}
