import { User } from '@firebase/auth';
import { Intercom as NativeIntercom } from '@capacitor-community/intercom';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Build } from '@stencil/core';

import { environment } from '../global/environment';
import { intercomIdentityVerification } from '../global/functions';
import { getPlatform } from '../global/capacitor-utils';

declare const Intercom: any;

declare global {
  interface Window {
    Intercom: any;
    nativeIntercom: any;
    intercomSettings: any;
    attachEvent: any;
  }
}

export async function identifyIntercomUser(
  user: User,
  groupDoc: GroupState['groupDoc']
) {
  const platform = getPlatform();
  const user_hash = await intercomIdentityVerification({ platform });
  if (!Capacitor.isNativePlatform()) {
    if (!user_hash) return;
    const intercomSettings: {
      api_base: string;
      app_id: string;
      user_id: string;
      user_hash: string;
      company?: {
        id: string;
        name: string;
      };
    } = {
      api_base: 'https://api-iam.intercom.io',
      app_id: environment.intercom.appId,
      user_id: user.uid,
      user_hash,
    };
    if (groupDoc?.group_id && groupDoc?.name) {
      intercomSettings.company = {
        id: groupDoc.group_id,
        name: groupDoc.name,
      };
    }
    window.intercomSettings = intercomSettings;
    window.Intercom?.update?.();
    return; // we use segment to send other identify and events to intercom
  }
  try {
    if (user_hash) {
      await NativeIntercom.setUserHash({ hmac: user_hash });
    }
    await PushNotifications.register();
    const params: {
      email?: string;
      userId: string;
      company?: {
        id: string;
        name: string;
      };
    } = {
      userId: user.uid,
    };
    if (user.email) {
      params.email = user.email;
    }
    if (groupDoc?.group_id && groupDoc?.name) {
      // Intercom native - follow issue:
      // https://github.com/capacitor-community/intercom/issues/24
      params.company = {
        id: groupDoc.group_id,
        name: groupDoc.name,
      };
    }
    if (params.userId || params.email) {
      await NativeIntercom.registerIdentifiedUser(params);
    } else {
      await NativeIntercom.registerUnidentifiedUser();
    }
  } catch (err) {
    // push registration did not succeed - but that's ok
    console.info('intercom push register failed');
  }
}

export function openIntercomMessenger(
  channel: string,
  defaultMessage?: string
) {
  window.location.hash = channel;
  if (Capacitor.isNativePlatform()) {
    return NativeIntercom.displayMessageComposer({
      message: defaultMessage || '',
    });
  } else if (Intercom) {
    Intercom('showNewMessage', defaultMessage);
  }
}

export function shutdownIntercom() {
  if (Intercom) {
    Intercom('shutdown');
  }
}

function bootIntercom() {
  if (!window.Intercom) return;
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: environment.intercom.appId,
  });
}

export function initIntercom() {
  if (!Build.isBrowser) return;
  if (Capacitor.isNativePlatform()) return;
  // prettier-ignore
  // @ts-ignore
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=`https://widget.intercom.io/widget/${environment.intercom.appId}`; s.addEventListener('load', bootIntercom); var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}
