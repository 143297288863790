import { SignUpAttribution } from '@og-shared/types';
import {
  StorageKeys,
  getStorageKey,
  saveStorageKeyIfNotPresent,
} from './storage';

export async function getSavedAttribution() {
  const [
    invited_by_uid,
    og_utm_campaign,
    og_utm_content,
    og_utm_medium,
    og_utm_source,
  ] = await Promise.all([
    getStorageKey(StorageKeys.og_invited_by_uid),
    getStorageKey(StorageKeys.og_utm_campaign),
    getStorageKey(StorageKeys.og_utm_content),
    getStorageKey(StorageKeys.og_utm_medium),
    getStorageKey(StorageKeys.og_utm_source),
  ]);
  const attribution: SignUpAttribution = {
    invited_by_uid,
    og_utm_campaign,
    og_utm_content,
    og_utm_medium,
    og_utm_source,
  };
  return attribution;
}

export async function saveAttributionIfNotPresent(
  attribution: Partial<SignUpAttribution>
) {
  if (attribution.invited_by_uid && attribution.invited_by_uid !== '') {
    saveStorageKeyIfNotPresent(
      StorageKeys.og_invited_by_uid,
      attribution.invited_by_uid
    );
  }
  if (attribution.og_utm_source && attribution.og_utm_source !== '') {
    saveStorageKeyIfNotPresent(
      StorageKeys.og_utm_source,
      attribution.og_utm_source
    );
  }
  if (attribution.og_utm_medium && attribution.og_utm_medium !== '') {
    saveStorageKeyIfNotPresent(
      StorageKeys.og_utm_medium,
      attribution.og_utm_medium
    );
  }
  if (attribution.og_utm_campaign && attribution.og_utm_campaign !== '') {
    saveStorageKeyIfNotPresent(
      StorageKeys.og_utm_campaign,
      attribution.og_utm_campaign
    );
  }
  if (attribution.og_utm_content && attribution.og_utm_content !== '') {
    saveStorageKeyIfNotPresent(
      StorageKeys.og_utm_content,
      attribution.og_utm_content
    );
  }
}
