import LogRocket from 'logrocket';
import { Build } from '@stencil/core';
import { User } from 'firebase/auth';
import { addMonths } from 'date-fns';

import { sentrySetLogRocketUrl } from './sentry';
import { environment } from '../global/environment';

export function initLogRocket(params: {
  jsVersion: string;
  user: User;
  groupId: string | null;
}) {
  const { jsVersion, user, groupId } = params;
  if (!Build.isBrowser) return;
  if (!LogRocket || !environment.logRocket.appId) return;

  const aMonthAgo = addMonths(new Date(), -3);
  const creationDate =
    user.metadata.creationTime && new Date(user.metadata.creationTime);
  if (!creationDate || creationDate < aMonthAgo) {
    // this user was created longer than a month ago
    // don't record sessions
    return;
  }
  LogRocket.init(environment.logRocket.appId, {
    release: jsVersion,
    dom: {
      baseHref: environment.logRocket.assetUrl,
    },
  });
  LogRocket.getSessionURL(sessionURL => {
    sentrySetLogRocketUrl(sessionURL);
  });
  LogRocket.identify(user.uid, {
    name: user.displayName ?? '',
    email: user.email ?? '',
    group_id: groupId ?? '',
  });
}
