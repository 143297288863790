import { Capacitor } from '@capacitor/core';
import { toastController, alertController } from '@ionic/core';
import { translate } from './translate';
import { getPlatform } from './capacitor-utils';
import { openStoreUrl } from './open-store-url';

export async function promptRefresh() {
  const toast = await toastController.create({
    message: translate('NEW_VERSION_AVAILABLE'),
    buttons: [
      {
        text: translate('DISMISS'),
        role: 'cancel',
      },
      {
        text: translate('UPDATE'),
        handler: () => reloadOrUpdateNative(),
      },
    ],
    color: 'dark',
  });
  return toast.present();
}

export async function alertForceReload() {
  const alert = await alertController.create({
    backdropDismiss: false,
    header: translate('UPDATE_REQUIRED'),
    message: translate('UPDATE_REQUIRED_DESC'),
    buttons: [
      {
        text: translate('UPDATE'),
        handler: () => reloadOrUpdateNative(),
      },
    ],
  });
  return alert.present();
}

const reloadOrUpdateNative = async () => {
  if (Capacitor.isNativePlatform()) {
    return openStoreUrl(getPlatform() === 'ios' ? 'ios' : 'android');
  }
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.getRegistration();
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (let reg of registrations) {
      reg.update();
    }
    if (registration && registration.waiting) {
      registration.waiting.postMessage('skipWaiting');
    }
  }
  window.location.reload();
};
