import {
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
  Timestamp,
} from 'firebase/firestore';

import { AnalyticsEvent } from '../analytics';
import { authState } from '../../store/auth.store';
import { groupState } from '../../store/group.store';

export type AnalyticsDoc = {
  uid: string;
  eventName: AnalyticsEvent;
  metadata: any;
  event_id: string;
  group_id: string | null;
  timestamp: Timestamp;
};
export async function firestoreLogAnalyticsEvent(
  eventName: AnalyticsEvent,
  metadata: any = {}
) {
  const uid = authState.userInfo.uid;
  if (!uid) return;
  const group_id = groupState.groupDoc.group_id ?? null;
  const newDocRef = doc(collection(getFirestore(), 'analytics-events'));
  const eventDoc: AnalyticsDoc = {
    uid,
    eventName,
    metadata,
    event_id: newDocRef.id,
    group_id,
    timestamp: serverTimestamp() as any,
  };
  const path = `analytics-events/${eventDoc.event_id}`;
  const docRef = doc(getFirestore(), path);
  return setDoc(docRef, eventDoc, { merge: true });
}
