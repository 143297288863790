import type { User } from 'firebase/auth';
import { Build } from '@stencil/core';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { formatDate } from '@og-shared/utils';
import { GroupDocumentWithSubscription } from '@og-shared/types';

import { getPlatform } from '../global/capacitor-utils';
import { environment } from '../global/environment';
import { getStorageKey, setStorageKey, StorageKeys } from '../global/storage';
import { getSavedAttribution } from '../global/attribution-utils';
import { firestoreLogAnalyticsEvent } from './firestore/analytics';
import { facebookTrackCompleteRegistration } from './facebook-tracking';
import { initLogRocket } from './log-rocket';
import { identifyIntercomUser } from './intercom';

export enum AnalyticsEvent {
  // We recommend event names built from a noun and past-tense verb.
  // For more information about best practices in event naming, check out https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/ naming conventions for clean data.
  // object action - properties of the object.
  // noun_verb past tense
  // product_viewed, { product_id: 'id123' }
  // budget_viewed, { budget_id: '123' }
  // integration_noun_verb
  account_created = 'account_created',
  accounts_imported = 'accounts_imported',
  active_group_changed = 'active_group_changed',
  analyze_spending_skipped = 'analyze_spending_skipped',
  uncategorized_count_increased = 'uncategorized_count_increased',
  budgets_manually_filled = 'budgets_manually_filled', // fill details
  budgets_manually_rebalanced = 'budgets_manually_rebalanced', // rebalance details
  choice_made = 'choice_made', // used for tracking user choices in UI - always pass { choice: string } metadata
  goal_set = 'goal_set',
  feedback_prompt_viewed = 'feedback_prompt_viewed',
  feedback_inputs_viewed = 'feedback_inputs_viewed',
  feedback_thanks_viewed = 'feedback_thanks_viewed',
  fund_budgets_viewed = 'fund_budgets_viewed',
  pre_rebalance_viewed = 'pre_rebalance_viewed',
  rebalance_viewed = 'rebalance_viewed',
  confetti_viewed = 'confetti_viewed',
  demo_mode_viewed = 'demo_mode_viewed',
  image_shared = 'image_shared',
  invite_join_failed = 'invite_join_failed', // via join-page with invite link
  invite_join_succeeded = 'invite_join_succeeded', // via join-page with invite link
  language_changed = 'language_changed',
  messenger_opened = 'messenger_opened',
  navigated_from_deep_link = 'navigated_from_deep_link',
  need_help_prompt_viewed = 'need_help_prompt_viewed',
  onboarding_modal_viewed = 'onboarding_modal_viewed',
  onboarding_success_viewed = 'onboarding_success_viewed',
  partner_join_succeeded = 'partner_join_succeeded',
  partner_join_failed = 'partner_join_failed',
  plaid_link_errored = 'plaid_link_errored',
  plaid_link_saved = 'plaid_link_saved', // link metadata
  plaid_link_updated = 'plaid_link_updated', // link metadata
  preview_transactions_viewed = 'preview_transactions_viewed',
  product_added = 'product_added', // clicked on pricing
  product_purchased = 'product_purchased', //
  push_notification_opened = 'push_notification_opened',
  rating_selected = 'rating_selected',
  restore_purchase_denied = 'restore_purchase_denied', // customer tried to restore a purchase from a different group on a new group.
  review_in_app_prompted = 'review_in_app_prompted', // api called to present review prompt
  product_purchase_cancelled = 'product_purchase_cancelled',
  url_opened = 'url_opened', // called brower.open
  session_started = 'session_started', // called once on og-app init
  stripe_checkout_cancelled = 'stripe_checkout_cancelled', // called when redirected from subscribe/cancel - after stripe checkout
  stripe_launched_checkout = 'stripe_launched_checkout',
  transaction_categorized = 'transaction_categorized', // transaction categorized via drop
  testimonial_saved = 'testimonial_saved',
  transaction_saved = 'transaction_saved', // transaction saved via edit-transaction view
  import_budget_viewed = 'import_budget_viewed',
  import_review_budget_viewed = 'import_review_budget_viewed',
  import_review_spending_viewed = 'import_review_spending_viewed',
  budget_imported = 'budget_imported',
  import_transactions_viewed = 'import_transactions_viewed',
  import_settings_viewed = 'import_settings_viewed',
  import_map_accounts_viewed = 'import_map_accounts_viewed',
  import_map_categories_viewed = 'import_map_categories_viewed',
  import_select_viewed = 'import_select_viewed',
  transactions_imported = 'transactions_imported',
  transactions_searched = 'transactions_searched',
  unknown_error_viewed = 'unknown_error_viewed',
  // page views
  page_viewed_access_codes = 'page_viewed_access_codes',
  page_viewed_account = 'page_viewed_account',
  page_viewed_account_proj = 'page_viewed_account_proj',
  page_viewed_accounts = 'page_viewed_accounts',
  page_viewed_net_worth = 'page_viewed_net_worth',
  page_viewed_all_transactions = 'page_viewed_all_transactions',
  page_viewed_budgets = 'page_viewed_budgets',
  page_viewed_budget = 'page_viewed_budget',
  page_viewed_budget_proj = 'page_viewed_budget_proj',
  page_viewed_budget_report = 'page_viewed_budget_report',
  page_viewed_cash_flow = 'page_viewed_cash_flow',
  page_viewed_connections = 'page_viewed_connections',
  page_viewed_help = 'page_viewed_help',
  page_viewed_investments = 'page_viewed_investments',
  page_viewed_join_partner = 'page_viewed_join_partner',
  page_viewed_landing = 'page_viewed_landing',
  page_viewed_oath_link = 'page_viewed_oath_link',
  page_viewed_reports = 'page_viewed_reports',
  page_viewed_search_transactions = 'page_viewed_search_transactions',
  page_viewed_subscribe = 'page_viewed_subscribe',
  page_viewed_subscribe_status = 'page_viewed_subscribe_status',
  page_viewed_sign_in_email = 'page_viewed_sign_in_email',
  page_viewed_select_group = 'page_viewed_select_group',
  // ordered by flow
  page_viewed_create_group = 'page_viewed_create_group',
  page_viewed_referred = 'page_viewed_referred',
  page_viewed_intro = 'page_viewed_intro',
  page_viewed_intro_goal = 'page_viewed_intro_goal',
  page_viewed_intro_link_items = 'page_viewed_intro_link_items',
  page_viewed_intro_link_accounts = 'page_viewed_intro_link_accounts',
  page_viewed_intro_analyze = 'page_viewed_intro_analyze',
  page_viewed_intro_review_budget = 'page_viewed_intro_review_budget',
  page_viewed_intro_create_goal = 'page_viewed_intro_create_goal',
  page_viewed_categorize_spending = 'page_viewed_categorize_spending',
  page_viewed_intro_sug_income = 'page_viewed_intro_sug_income',
  page_viewed_intro_income = 'page_viewed_intro_income',
  page_viewed_intro_sug_bills = 'page_viewed_intro_sug_bills',
  page_viewed_intro_bills = 'page_viewed_intro_bills',
  viewed_intro_runway = 'viewed_intro_runway',
  page_viewed_intro_sug_savings = 'page_viewed_intro_sug_savings',
  page_viewed_intro_savings = 'page_viewed_intro_savings',
  page_viewed_intro_sug_spending = 'page_viewed_intro_sug_spending',
  page_viewed_intro_spending = 'page_viewed_intro_spending',
  // ends on cash_flow
  page_viewed_one_goal = 'page_viewed_one_goal',
  page_viewed_overview = 'page_viewed_overview',
  page_viewed_dashboard = 'page_viewed_dashboard',
  page_viewed_share = 'page_viewed_share',
  page_viewed_page_not_found = 'page_viewed_page_not_found',

  projections_table_viewed = 'projections_table_viewed',
  push_prompt_viewed = 'push_prompt_viewed',
  push_registered = 'push_registered',
  video_played = 'video_played',
  viewed_products = 'viewed_products',
  missing_permission_viewed = 'missing_permission_viewed',
  release_modal_viewed = 'release_modal_viewed',
  share_my_link_viewed = 'share_my_link_viewed',
}

export function initAnalytics() {
  if (!Build.isBrowser || !analytics) return;
  analytics.load(environment.segmentKey);
}

export async function identifyUser(params: {
  user: User;
  jsVersion: string;
  groupDoc: GroupState['groupDoc'];
}) {
  if (!Build.isBrowser || !analytics) return;
  const { user, jsVersion, groupDoc } = params;
  const creationDate = user.metadata.creationTime
    ? new Date(user.metadata.creationTime)
    : null;

  const created_at = creationDate ? creationDate.getTime() / 1000 : null;
  const sign_up_date = creationDate ? formatDate(creationDate) : 'unknown';
  analytics.identify(user.uid, {
    name: user.displayName,
    email: user.email,
    created_at,
    sign_up_date,
    group_id: groupDoc.group_id,
    js_version: jsVersion,
    version: jsVersion,
  });
  identifyIntercomUser(user, groupDoc);
  initLogRocket({ jsVersion, user, groupId: groupDoc.group_id });
  if (user.metadata.creationTime !== user.metadata.lastSignInTime) {
    // user did not just sign up - they are returning
    // once they sign out (or into a different device) this method will work
    return;
  }
  const accountCreated = await getStorageKey(StorageKeys.og_account_created);
  // if the user refreshes the page after signing up we'll still get here
  // because of the notes above - we need to store if they have signed in or not
  if (accountCreated) return; // returning user
  trackAnalyticsEvent(AnalyticsEvent.account_created, {
    auth: user.providerData?.[0]?.providerId ?? 'unknown',
  });
  facebookTrackCompleteRegistration();
  await setStorageKey(StorageKeys.og_account_created, true);
  // if this is a new user sign up - let's save the attribution to analytics
  const attribution = await getSavedAttribution();
  analytics.identify(user.uid, attribution);
}

export async function identifyAnonymousUser(js_version: string) {
  if (!Build.isBrowser || !analytics) return;
  analytics.identify({
    js_version,
  });
}
export function trackAnalyticsEvent(
  eventName: AnalyticsEvent,
  metadata: {}
): any;
export function trackAnalyticsEvent(
  eventName: AnalyticsEvent.product_purchased,
  params: {
    purchase_id: string;
    source: 'website'; // 'app' @todo add once we have fb native SDK;
    currency: string;
    price: string;
  }
): any;

export function trackAnalyticsEvent(
  eventName: AnalyticsEvent.viewed_products,
  params: {
    product_ids: string[];
  }
): any;

export function trackAnalyticsEvent(eventName: AnalyticsEvent): any;
export function trackAnalyticsEvent(eventName: AnalyticsEvent, metadata?: any) {
  if (!Build.isBrowser) return;
  if (!analytics) return;
  const metadataWithPlatform = { ...(metadata ?? {}), platform: getPlatform() };
  analytics.track(eventName, metadataWithPlatform);
  firestoreLogAnalyticsEvent(eventName, metadataWithPlatform);
  logEvent(getAnalytics(), eventName, metadataWithPlatform);
}

export function trackGroup(groupDocument: GroupDocumentWithSubscription) {
  if (!Build.isBrowser) return;
  if (!analytics) return;
  // https://segment.com/docs/connections/spec/group
  analytics.group(groupDocument.group_id, {
    // rest of group traits are sent from server
  });
}
