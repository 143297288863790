import { UserDocument } from '@og-shared/types';
import { doc, getFirestore, getDoc, setDoc } from 'firebase/firestore';

export async function firestoreGetUserDocument(uid: string) {
  const path = `users/${uid}`;
  const docRef = doc(getFirestore(), path);
  const snap = await getDoc(docRef);
  const userDoc = snap.data() as UserDocument;
  return userDoc;
}

export async function firestoreUpdateUserDocument(
  uid: string,
  update: Partial<UserDocument>
) {
  const path = `/users/${uid}`;
  const docRef = doc(getFirestore(), path);
  return setDoc(docRef, update, { merge: true });
}

export async function firestoreSeenNewReleases(
  user_id: string,
  last_seen_version_number: number
) {
  return firestoreUpdateUserDocument(user_id, { last_seen_version_number });
}
