import { createStore } from '@stencil/store';

const initialState: TransactionsState = {
  edit_transaction: null as any,
  edit_transaction_original: null,
  reset_alert_viewed: false,
};

export const {
  state: transactionsState,
  onChange: onTransactionsStoreChange,
  reset: resetTransactionsState,
  dispose: disposeTransactionsState,
  on: onTransactionsStore,
} = createStore<TransactionsState>(initialState);
