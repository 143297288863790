import {
  exhaustiveCheck,
  OG_APP_STORE_URL,
  OG_PLAY_STORE_URL,
} from '@og-shared/utils';

import { openInNewWindow } from './browser';

export function openStoreUrl(store: 'ios' | 'android') {
  switch (store) {
    case 'ios':
      return openInNewWindow({
        url: OG_APP_STORE_URL,
      });
    case 'android':
      return openInNewWindow({
        url: OG_PLAY_STORE_URL,
      });
    default:
      return exhaustiveCheck(store);
  }
}
