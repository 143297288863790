import { RouterDirection, loadingController } from '@ionic/core';
import { AnalyticsEvent, trackAnalyticsEvent } from '../services/analytics';
import { asaAuthIfParams } from './asa-utils';
import { getUrlParameters } from './get-url-parameters';
import { signInWithToken } from './firebase';
import { environment } from './environment';
import { getAndSetPartnerData } from './get-partner-data';

export async function deepLinkInApp(url: string) {
  let success = false;
  const baseUrl = environment.baseUrl;
  if (!url?.includes(baseUrl)) return success;
  const loading = await loadingController.create({});

  try {
    await loading.present();
    const path = url.split(baseUrl)[1];
    const params = getUrlParameters({ url });
    const { token, partnerId } = params;
    if (token) {
      await signInWithToken(token);
    }
    await getAndSetPartnerData(partnerId);
    await asaAuthIfParams(params);
    await routeInApp(path);
    trackAnalyticsEvent(AnalyticsEvent.navigated_from_deep_link, { url, path });
    success = true;
  } catch (err) {
    console.error('error redirecting to ', url);
    success = false;
  } finally {
    loading.dismiss();
  }
  return success;
}

export function routeInApp(path: string, direction?: RouterDirection) {
  return document.querySelector('ion-router')?.push(path, direction);
}
