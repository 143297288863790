import {
  collection,
  getFirestore,
  query,
  limit,
  onSnapshot,
  where,
  orderBy,
  doc,
  setDoc,
  getDocs,
} from 'firebase/firestore';
import { Env, ReleaseDocument } from '@og-shared/types';

import { getPlatform } from '../../global/capacitor-utils';
import { showNewReleasesModal } from '../../store/actions/releases';
import { setLatestReleases } from '../../store/actions/user';
import { authState } from '../../store/auth.store';

export const firestoreListenToNewReleasesChanges = (env: Env) => {
  const platform = getPlatform();
  const path = 'releases';
  const collectionRef = collection(getFirestore(), path);
  const keyOne: keyof ReleaseDocument = 'env';

  const q = query(
    collectionRef,
    where(keyOne, 'array-contains', env),
    orderBy('version_number', 'desc'),
    limit(2)
  );
  const releasesListener = onSnapshot(q, querySnapshot => {
    const releases = querySnapshot.docs
      .map(doc => doc.data() as ReleaseDocument)
      .filter(r => r.platform.includes(platform));
    if (releases[0] && releases[0].maintenance_mode) {
      return showNewReleasesModal(releases, true);
    }
    setLatestReleases(releases);
  });
  return releasesListener;
};

export function firestoreGetReleases(
  env: Env,
  callback: (releases: ReleaseDocument[]) => void,
  docLimit = 50
) {
  if (authState.userInfo.admin) {
    // if admin - always query all releases;
    env = 'dev';
  }
  //
  const path = 'releases';
  const collectionRef = collection(getFirestore(), path);
  const q = query(
    collectionRef,
    where('env', 'array-contains', env),
    orderBy('version_number', 'desc'),
    limit(docLimit)
  );
  return onSnapshot(q, querySnapshot => {
    const releases = querySnapshot.docs.map(
      doc => doc.data() as ReleaseDocument
    );
    callback(releases);
  });
}

export async function firestoreSaveRelease(release: ReleaseDocument) {
  if (!release.release_id) {
    const newDocRef = doc(collection(getFirestore(), 'releases'));
    release.release_id = newDocRef.id;
  }
  const path = `releases/${release.release_id}`;
  const docRef = doc(getFirestore(), path);
  return setDoc(docRef, release, { merge: true });
}

export async function firestoreGetNewReleases(
  env: Env,
  version_number: number
) {
  const platform = await getPlatform();
  const path = 'releases';
  const collectionRef = collection(getFirestore(), path);
  const q = query(
    collectionRef,
    where('env', 'array-contains', env),
    where('version_number', '>', version_number),
    orderBy('version_number', 'desc')
  );
  const snap = await getDocs(q);
  return snap.docs
    .map(doc => doc.data() as ReleaseDocument)
    .filter(doc => doc.platform?.includes(platform));
}
