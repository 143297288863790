import * as Sentry from '@sentry/capacitor';
import { CaptureContext } from '@sentry/types';
import { Env } from '@stencil/core';

import { version, buildNumber } from '../../package.json';
import { environment } from '../global/environment';

export function sentryInit() {
  if (environment.env === 'local' || Env.isLocal === 'true') return;
  Sentry.init({
    environment: environment.env,
    dsn: 'https://32e0d1621a04468ab3c418db0355e533@o4504805507727360.ingest.sentry.io/4504805565595648',

    // Set your release version, such as "getsentry@1.0.0"
    release: `one-goal@${version}`,
    // Set your dist version, such as "1"
    dist: `${buildNumber}`,
    tracesSampleRate: 1,
    integrations: [Sentry.browserTracingIntegration()],
  });
}

export function sentrySetUser(user: Sentry.User) {
  Sentry.setUser(user);
}

export function sentrySetLogRocketUrl(sessionURL: string) {
  Sentry.getCurrentScope().setExtra('logRocketSessionUrl', sessionURL);
}

export function sentryLogError(
  exception: any,
  captureContext?: CaptureContext
) {
  Sentry.captureException(exception, captureContext);
}
