import { ReleaseDocument } from '@og-shared/types';

import { firestoreGetUserDocument } from '../../services/firestore/user';
import { resetBudgetsState } from '../budgets.store';
import { groupState, resetGroupState } from '../group.store';
import { resetTransactionsState } from '../transactions.store';
import { uiState, resetUiState } from '../ui.store';
import { setUserDoc } from '../auth.store';
import { getGroupUrl } from '../../global/get-group-url';
import { routeInApp } from '../../global/route-in-app';
import { resetBudgetViewState } from '../budget-view.store';
import { resetRulesState } from '../rules.store';

export const getUserDocument = async (uid: string) => {
  const user = await firestoreGetUserDocument(uid);
  if (user) {
    setUserDoc(user);
    return user;
  } else {
    console.error(`user document doesn't exist`);
    return null;
  }
};

export const changeGroup = async (groupId: string, path?: string) => {
  const groupPath = getGroupUrl(groupId, `/${path ?? ''}`);
  resetOgState();
  routeInApp(groupPath, 'root');
};

const resetOgState = () => {
  resetBudgetsState();
  const lastGroupId = groupState.lastGroupId;
  resetGroupState();
  groupState.lastGroupId = lastGroupId;
  resetTransactionsState();
  resetUiState();
  resetBudgetViewState();
  resetRulesState();
};

export const setLatestReleases = (releases: ReleaseDocument[]) => {
  let new_version = false;
  let force_reload = false;
  const currentVersionNumber = uiState.version_number;
  const latest_release = releases[0];
  if (latest_release && currentVersionNumber < latest_release.version_number) {
    new_version = true;
  }
  if (latest_release && latest_release.force_reload) {
    force_reload = true;
  }
  uiState.force_reload = force_reload;
  uiState.new_version = new_version;
};
