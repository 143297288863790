import { Build } from '@stencil/core';
import { Capacitor } from '@capacitor/core';
import { getApp, initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import {
  Auth,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  signInWithCustomToken,
} from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

import { signIn, isSignedOut } from '../store/actions/auth';
import { environment } from './environment';

export async function initFirebase() {
  if (!Build.isBrowser) return;
  const app = initializeApp(environment.firebaseConfig);
  initializeFirestore(app, { ignoreUndefinedProperties: true });
  // initEmulators();
  getFirebaseAuth().onAuthStateChanged(user => {
    if (user) {
      signIn(user);
    } else {
      isSignedOut();
    }
  });
  getAnalytics(app);
}

export function signInWithToken(token: string) {
  return signInWithCustomToken(getFirebaseAuth(), token);
}

export function signOutFirebase() {
  return getFirebaseAuth().signOut();
}

let auth: Auth;
export function getFirebaseAuth() {
  if (auth) return auth;
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(getApp(), { persistence: indexedDBLocalPersistence });
  } else {
    auth = getAuth();
  }
  return auth;
}
// firestore.enablePersistence().catch(err => {
//   if (err.code == 'failed-precondition') {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     // ...
//   } else if (err.code == 'unimplemented') {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     // ...
//   }
// });

export type ProviderOptions = 'all' | 'email-only';
// Firebase UI Stuff
let firebaseUiInstance: firebaseui.auth.AuthUI | undefined;
export async function setupFirebaseUI(params: {
  showSignUp: boolean;
  providerOptions: ProviderOptions;
  element: HTMLElement;
}) {
  if (!Build.isBrowser) return;
  const { showSignUp, providerOptions, element } = params;
  const firebaseui = await import('firebaseui');

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions:
      providerOptions === 'email-only'
        ? [
            {
              provider: 'password',
              requireDisplayName: true,
            },
          ]
        : [
            {
              provider: 'apple.com',
            },
            {
              provider: 'google.com',
            },
            {
              provider: 'password',
              requireDisplayName: true,
            },
          ],
    tosUrl: 'https://onegoalfinance.com/terms',
    privacyPolicyUrl: 'https://onegoalfinance.com/privacy-policy',
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    callbacks: {
      signInSuccessWithAuthResult: authResult => {
        if (authResult.additionalUserInfo.isNewUser) {
          authResult.user.sendEmailVerification();
        }
        return false;
      },
      // uiShown: () => {},
      signInFailure: e => {
        console.error('firebase ui sign in failure', e);
      },
    },
  };

  (uiConfig.callbacks as any)['uiChanged'] = () => {
    //https://github.com/firebase/firebaseui-web/issues/665#issuecomment-754835197
    if (!showSignUp) return;
    replaceSignInText('Sign in', 'Sign up');
  };
  // Initialize the FirebaseUI Widget using Firebase.
  firebaseUiInstance =
    firebaseui.auth.AuthUI.getInstance() ??
    new firebaseui.auth.AuthUI(getFirebaseAuth());
  // The start method will wait until the DOM is loaded.
  firebaseUiInstance.start(element, uiConfig);
}

export function replaceSignInText(
  findText: 'Sign in' | 'Sign up',
  replaceText: 'Sign in' | 'Sign up'
) {
  document.querySelectorAll('.firebaseui-idp-text').forEach(b => {
    const text = b.textContent;
    if (text && text.includes(findText)) {
      const newText = text.replace(findText, replaceText);
      b.textContent = newText;
    }
  });
  document.querySelectorAll('.firebaseui-title').forEach(b => {
    const text = b.textContent;
    if (text && text.includes(findText)) {
      const newText = text.replace(findText, replaceText);
      b.textContent = newText;
    }
  });
}

export function resetFirebaseUI() {
  firebaseUiInstance?.reset();
}
