/**
 *
 * @param params url can be location.search or location.href
 * @returns
 */
export function getUrlParameters(params: { url: string; decode?: boolean }) {
  // ?utm_source={{site_source_name}}&utm_medium={{placement}}&utm_campaign={{campaign.id}}&utm_content={{adset.id}}
  // https://davidwalsh.name/query-string-javascript

  const { url, decode = true } = params;

  if (!url || url === '' || !url.includes('?')) return {};
  const queryParamsString = url.split('?')[1];
  const queryParams = queryParamsString
    .split('&')
    .reduce((obj: { [key: string]: string }, keyVal) => {
      const [key, value] = keyVal.split('=');
      obj[key] = decode ? decodeURIComponent(value.replace(/\+/g, ' ')) : value;
      return obj;
    }, {});

  return queryParams;
}
