import { PushNotifications } from '@capacitor/push-notifications';
import { Env } from '@stencil/core';
import { createStore } from '@stencil/store';
import type {
  DeviceDocument,
  FeatureFlags,
  GroupDocumentWithSubscription,
} from '@og-shared/types';
import { keys, isDemoGroupId, BudgetType } from '@og-shared/utils';

import { version, buildNumber } from '../../package.json';
import { environment } from '../global/environment';
import {
  alertForceReload,
  promptRefresh,
} from '../global/update-available-prompt';
import { convertVersionToNumber, inIframe, waitFor } from '../global/utils';
import { getStorageKey, setStorageKey, StorageKeys } from '../global/storage';
import { authState } from './auth.store';

const local = Env?.isLocal === 'true'; // used for disabling sentry and showing dev UI

const version_number = convertVersionToNumber(version);
const env = environment.env;

const initialState: UiState = {
  active_route: null,
  active_tab: BudgetType.SPENDING,
  setting_due_date: false,
  show_demo_banner: false,
  celebrate_next: false,
  celebrating: false,
  condensed: false,
  dark_mode: false,
  env,
  exploding: false,
  negative_alert_shown: false,
  force_reload: false,
  frequency_view: 12,
  projection_segment: 1,
  features: {
    //@feature-flag - import transactions
    import_transactions: false,
    //@feature-flag - enable transfer from / to account
    budget_type_transfer: false,
    //@feature-flag - view access codes
    access_codes: false,
    //@feature-flag used for debugging
    view_account_projections: false,
    //@feature-flag show budget projections
    view_budget_projections: false,
    //@feature-flag testing start / end dates on budgets
    start_end_dates: false,
    //@feature-flag testing accounts cash flow page
    accounts_cash_flow: false,
    //@feature-flag budget views on reports/plan page
    budget_views: false,
    //@feature-flag tags - still a work in progress
    tags: false,
    //@feature-flag see the feature flags ui
    feature_flags: false,
    //@feature-flag see the "out of debt" date on income page
    out_of_debt_date: false,
    //@feature-flag see all categories on spending page
    spending_page_all: false,
    //@feature-flag choose type and suggestion when creating new category
    show_suggestions_new_category: false,
    //@feature-flag show categorize drag and drop view for ignored transactions
    show_categorize_transactions: false,
    //@feature-flag show exit button for iframe and in-app browser partner solutions
    show_exit_button: inIframe(),
    // white label features
    send_feedback: true,
    help_tab: true,
    rate_app: true,
    notification_settings: true,
    manage_users: true,
    billing: true,
    release_notes: true,
    refer_friends: true,
    set_one_goal: true,
    auto_fill_toggle: true,
    sign_out_button: true,
    create_group: true,
    // show what brings you to one goal page during intro (out of debt, save money, stop living paycheck to paycheck)
    intro_goal: true,
    net_worth: false,
    //@feature-flag allow amount to be pulled from parent budget - experimental
    parent_budget_id: false,
    //@feature-flag show dashboard instead of plan tab
    dashboard: false,
    //@feature-flag show suggested income during onboarding
    show_suggested_income: false,
    //@feature-flag show avatar in side menu
    show_avatar: true,
    //@feature-flag show premium badge in side menu next to group name
    show_premium_badge: true,
    //@feature-flag show change budget type in settings
    change_budget_type: true,
    //@feature-flag show change budget type in settings
    show_upgrade_via_partner: false,
    //@feature-flag show og_category on budget
    og_category: false,
    //@feature-flag show gift transaction option
    gift_transaction: true,
    //@feature-flag default asa link off
    asa_link: false,
    //@feature-flag allow linking to plaid investments
    plaid_investments: false,
    //@feature-flag show install native app prompt on web
    open_native_app_prompt: true,
    //@feature-flag add ability to show the reset demo dialogue that will reset the current budget
    show_demo_reset: true,
  },
  js_version: version,
  build_number: buildNumber,
  local,
  locale: 'en',
  new_version: false,
  onboarding: {
    push_notifications: 'loading',
  },
  snooze_chat: false,
  version_number,
  stored_partner_id: null,
};
export const {
  state: uiState,
  onChange: onUiStoreChange,
  reset: resetUiState,
  dispose: disposeUiStore,
  on: onUiStore,
} = createStore<UiState>(initialState);
onUiStoreChange('new_version', new_version => {
  if (!new_version) return;
  if (uiState.force_reload) {
    alertForceReload();
  } else {
    promptRefresh();
  }
});
onUiStoreChange('frequency_view', frequency => {
  setStorageKey(StorageKeys.og_frequency_view, frequency);
});
onUiStoreChange('projection_segment', projection_segment => {
  setStorageKey(StorageKeys.og_projection_segment, projection_segment);
});

export function setFeaturesFromGroup(groupDoc: GroupDocumentWithSubscription) {
  if (!groupDoc.entitlements?.transactions) {
    setFeatureFlags({ notification_settings: 'off' });
  }
  uiState.features.show_demo_reset =
    authState.userInfo.admin && isDemoGroupId(groupDoc.group_id);
  setFeatureFlags(groupDoc.features);
  uiState.show_demo_banner = isDemoGroupId(groupDoc.group_id);
}

export function setFeatureFlags(features?: FeatureFlags | undefined) {
  if (!features) return;
  keys(features).map(key => {
    if (features[key] === 'off') {
      uiState.features[key] = false;
    } else if (features[key] === 'on') {
      uiState.features[key] = true;
    }
  });
}

export function setStoredPartnerId(partnerId: string) {
  uiState.stored_partner_id = partnerId;
}

export async function setOnboardingFromDeviceDoc(
  device: DeviceDocument | undefined,
  group_id: string
) {
  let push_notifications = false;
  const needsReview =
    device?.notifications?.[group_id]?.push_needs_review ??
    device?.notifications?.[group_id]?.push_uncategorized;
  const pushOff = typeof needsReview === 'number' && needsReview === 0;
  const pushOn = typeof needsReview === 'number' && needsReview > 0;
  const pushOnNoToken = pushOn && !device?.push_token;

  push_notifications = pushOnNoToken ? false : pushOn ? pushOn : pushOff;

  if (pushOn) {
    const { receive } = await PushNotifications.checkPermissions();
    if (receive !== 'granted') {
      push_notifications = false;
    }
  }
  uiState.onboarding = {
    push_notifications,
  };
}

export async function snoozeChat() {
  uiState.snooze_chat = true;
  const mins = 5;
  const ms = mins * 60 * 1000;
  await waitFor(ms);
  uiState.snooze_chat = false;
}

export async function loadStoredPreferences() {
  const frequency_view = await getStorageKey(StorageKeys.og_frequency_view);
  const projection_segment = await getStorageKey(
    StorageKeys.og_projection_segment
  );
  uiState.projection_segment = projection_segment;
  uiState.frequency_view = frequency_view;
}
