import { Build } from '@stencil/core';
import { environment } from '../global/environment';

// https://developers.facebook.com/docs/meta-pixel/reference#standard-events

declare const fbq: (m: 'track', event: string, params?: Object) => void;

export function facebookTrackCompleteRegistration() {
  // When a registration form is completed.
  // A person submits a completed subscription or signup form.
  if (!canTrack()) return;
  fbq('track', 'CompleteRegistration');
}

export function facebookTrackLead() {
  // When a sign up is completed.
  // A person clicks on pricing.
  if (!canTrack()) return;
  fbq('track', 'Lead');
}

export function facebookTrackContact() {
  // When a person initiates contact with your business via telephone, SMS, email, chat, etc.
  // A person submits a question about a product.
  if (!canTrack()) return;
  fbq('track', 'Contact');
}

export function facebookTrackInitiateCheckout() {
  // When a person enters the checkout flow prior to completing the checkout flow.
  // A person clicks on a checkout button.
  if (!canTrack()) return;
  fbq('track', 'InitiateCheckout');
}

export function facebookTrackSubscribe() {
  // When a person applies to a start a paid subscription for a product or service you offer.
  // A person subscribes to your streaming service.
  if (!canTrack()) return;
  fbq('track', 'Subscribe');
}

export function facebookTrackViewContent() {
  // A visit to a web page you care about (for example, a product page or landing page).
  // ViewContent tells you if someone visits a web page's URL, but not what they see or do on that page.
  // A person lands on a product details page.
  if (!canTrack()) return;
  fbq('track', 'ViewContent');
}

function canTrack() {
  if (!Build.isBrowser || environment.env !== 'prod') return false;
  if (!(window as any).fbq) return false;
  return true;
}
