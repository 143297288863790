import { ModalOptions, modalController } from '@ionic/core';
import { LocaleString, ReleaseDocument, UserDocument } from '@og-shared/types';

import { environment } from '../../global/environment';
import { canDismiss } from '../../global/ion-utils';
import { firestoreGetNewReleases } from '../../services/firestore/releases';
import { firestoreSeenNewReleases } from '../../services/firestore/user';
import { uiState } from '../ui.store';

export const checkForNewReleases = async (
  uid: string,
  userDoc: UserDocument | null
) => {
  if (!uiState.features.release_notes) return false;
  if (!userDoc?.last_seen_version_number) {
    await firestoreSeenNewReleases(uid, uiState.version_number);
    return false;
  }
  if (!userDoc) return false;
  const newReleases = await firestoreGetNewReleases(
    environment.env,
    userDoc.last_seen_version_number
  );
  const language: LocaleString = userDoc.locale ?? 'en';
  const releasesWithNotes = newReleases.filter(
    release => release.text[language]
  );
  if (releasesWithNotes && releasesWithNotes.length) {
    const releases = releasesWithNotes.sort((a, b) =>
      a.version_number > b.version_number ? 1 : -1
    );
    // show old release first
    const onDismissReleases = () => {
      const version_numbers = releases.map(release => release.version_number);
      const highest_version_number = Math.max(...version_numbers);
      firestoreSeenNewReleases(uid, highest_version_number);
    };
    await showNewReleasesModal(releases, false, onDismissReleases);
    return true;
  }
  return false;
};

export async function showNewReleasesModal(
  releases: ReleaseDocument[],
  maintenance_mode?: boolean,
  onDidDimiss?: () => void
) {
  const modalOptions: ModalOptions = {
    backdropDismiss: false,
    component: 'og-release-modal',
    cssClass: 'og-small-modal',
    componentProps: {
      releases,
      maintenance_mode,
      modal: modalController,
    },
    canDismiss,
  };
  const modal = await modalController.create(modalOptions);
  await modal.present();
  if (!onDidDimiss) return;
  await modal.onDidDismiss();
  onDidDimiss();
}
